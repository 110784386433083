import React from 'react'
import './SideMenu.css'
import home from './home.png'
import dashboard from './dashboard.png'
import product from './product.png'
import helpcenter from './helpcenter.png'
import setting from './setting.png'

export default function SideMenu() {
    return (
        <div>
            <nav className="col-md-2 d-none d-md-block sidebar" id='sideMenu'>
                <div className="sidebar-sticky">
                    <ul className="nav flex-column">
                        <li className="nav-item active">
                            <a className="nav-link" href="/">
                                <span data-feather="home"></span>
                                eLeck
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                <span data-feather="file"></span>
                                <img src={home} alt="" />    Home
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                <span data-feather="shopping-cart"></span>
                                <img src={dashboard} alt="" />   Dashboard
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                <span data-feather="users"></span>
                                <img src={product} alt="" />   Product
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                <span data-feather="bar-chart-2"></span>
                                <img src={helpcenter} alt="" /> Help Center
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                <span data-feather="layers"></span>
                                <img src={setting} alt="" />  Setting
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}
