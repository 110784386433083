import React from 'react'
import Logo from './Logo.png'
import './ForgotPass.css'
import { useState } from 'react'
import { CognitoUser } from 'amazon-cognito-identity-js'
import UserPool from '../Context/Notes/UserPool'
import { useNavigate } from 'react-router-dom'

export default function UserConfirmation() {

    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [labelMsg, setLabelMsg] = useState('');
    let navigate = useNavigate();

    const getUser = () => {
        return new CognitoUser({
            Username: email,
            Pool: UserPool
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();

        getUser().confirmRegistration(code, true, (data, err) => {
            if (err) {
                console.log(err);
            }
            console.log(data);
            setLabelMsg('User confirmation done.')
            navigate("/");
        });
    };

    return (
        <div>
            <div className="forgot_center">
                <div className="forgot_logo">
                    <img src={Logo} alt="logo" />
                </div>
                <div className="forgot-txt">
                    <p>A confirmation code have been sent on your email.</p>
                </div>
                <form onSubmit={onSubmit}>
                    <div className='email_input'>
                        <input type="text" className='email_txt' name='email' value={email} onChange={e => setEmail(e.target.value)} placeholder='Enter your email' required />
                    </div>
                    <div className='code_input'>
                        <input type="text" className='email_txt' name='code' value={code} onChange={e => setCode(e.target.value)} placeholder='Enter Confirmation Code' required />
                    </div>
                    <div className="btn_container" style={{marginTop: "25px"}}>
                        <button className='req_btn'>Submit</button>
                    </div>
                </form>
                <div className="privacy" style={{ paddingTop: "100px" }}>
                    <a className='policy' href="/">Term of use. Privacy policy</a>
                </div>
            </div>
        </div>
    )
}
