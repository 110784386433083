import './App.css';
import Login from './components/Login';
import Signup from './components/Signup'
import UserConfirmation from './components/UserConfirmation'
import ForgetPass from './components/ForgetPass'
import Dashboard from './components/Dashboard'
import AuthState from './Context/Notes/AuthState'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <AuthState>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/userconfirmation' element={<UserConfirmation />} />
            <Route path='/forgetpassword' element={<ForgetPass />} />
            <Route path='/dashboard' element={<Dashboard />} />
          </Routes>
        </AuthState>
      </Router>
    </>
  );
}

export default App;
