import { React, useContext } from 'react'
import Logo from './Logo.png'
import './Login.css'
import { Link } from "react-router-dom";
import authContext from '../Context/Notes/authContext';

export const Login = () => {

    const { email, setEmail, password, setPassword, UserLogin } = useContext(authContext);

    const handleLogin = (e) => {
        e.preventDefault();

        UserLogin()
    };

    return (
        <div className='center'>
            <div className="app-logo">
                <img src={Logo} className="app-logo" alt="Logo" />
            </div>
            <div className="login-msg">
                <p className='msg'>Hi, Please login to your account.</p>
            </div>
            <form onSubmit={handleLogin}>
                <div className="username-input">
                    <input type="text" className='form-input' name='email' value={email} onChange={e => setEmail(e.target.value)} placeholder='Username' required />
                </div>
                <div className="password-input">
                    <input type="password" className='form-input' name='password' value={password} onChange={e => setPassword(e.target.value)} placeholder='Password' required />
                </div>
                <div className="checkbox-container">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <label className='checkbox-label'>Remember me</label>
                    <Link to='/forgetpassword' className="forget">Forget Password</Link>
                </div>
                <div className="button-container">
                    <button className='login-btn' >Login</button>
                    {/* <button className='signup-btn' >Sign up</button> */}
                    <Link className='signup-btn' to="/signup">Sign up</Link>
                </div>
            </form>
            <div className="privacy">
                <Link className='policy' href="/policy">Term of use. Privacy policy</Link>
            </div>
        </div>
    )
}

export default Login


