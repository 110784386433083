import React from 'react'
import './Signup.css'
import Logo from './Logo.png'
import { useState } from 'react'
import UserPool from '../Context/Notes/UserPool'
import { Link, useNavigate } from "react-router-dom";

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

export const Signup = () => {
    let navigate = useNavigate();

    const [input, setInput] = useState({
        email: '',
        password: '',
        confirmPassword: ''
    })
    const [error, setError] = useState({
        email: '',
        password: '',
        confirmPassword: ''
    })
    const onInputChange = e => {
        const { name, value } = e.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(e);
    }

    const validateInput = e => {
        let { name, value } = e.target;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };

            switch (name) {
                case "email":
                    if (!value) {
                        stateObj[name] = "Please enter Email.";
                    }
                    break;

                case "password":
                    if (!value) {
                        stateObj[name] = "Please enter Password.";
                    } else if (input.confirmPassword && value !== input.confirmPassword) {
                        stateObj["confirmPassword"] = "Password and Confirm Password does not match.";
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
                    }
                    break;

                case "confirmPassword":
                    if (!value) {
                        stateObj[name] = "Please enter Confirm Password.";
                    } else if (input.password && value !== input.password) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                    }
                    break;

                default:
                    break;
            }

            return stateObj;
        });
    }

    const HandleSignup = () => {
        const dataEmail = {
            Name: "email",
            Value: input.email
        }

        const userAttribut = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);

        UserPool.signUp(input.email, input.password, [userAttribut], null, (err, result) => {
            if (err) {
                alert(err.message || JSON.stringify(err));
                return;
            }
            console.log('user name is ' + result);
            RedirectPage();
        });
    }

    const RedirectPage = () => {
        navigate("/userconfirmation");
    };

    return (
        <div className='signup-container'>
            <div className="app-logo">
                <img src={Logo} alt="logo" />
            </div>
            <div className="txt-msg">
                <p className='msg'>Welcome, Please sign up your account.</p>
            </div>
            <form onSubmit={HandleSignup}>
                <div className='fullname'>
                    <input type="text" className='fname' name='firstname' placeholder='First Name' />
                    <input type="text" className='lname' name='lastname' placeholder='Last Name' />
                </div>
                <div className="username">
                    <input type="text" className='txt_field' name='username' placeholder='Username' />
                </div>
                <div className="email">
                    <input type="email" className='txt_field' name='email' value={input.email} onChange={onInputChange} onBlur={validateInput} placeholder='Email' required />
                    {error.email && <span className='err'>{error.email}</span>}
                </div>
                <div className="password">
                    <input type="password" className='txt_field' name="password" value={input.password} onChange={onInputChange} onBlur={validateInput} placeholder='Password' required />
                    {error.password && <span className='err'>{error.password}</span>}
                </div>
                <div className="confirmpass">
                    <input type="password" className='txt_field' name="confirmPassword" value={input.confirmPassword} onChange={onInputChange} onBlur={validateInput} placeholder='Confirm Password' required />
                    {error.confirmPassword && <span className='err'>{error.confirmPassword}</span>}
                </div>
                <div className="checkbox-container">
                    <input className='check_box' type="checkbox" name="terms" />
                    <span className="checkmark"></span>
                    <label htmlFor="" className="checkbox_txt">I agree with terms and conditions.</label>
                </div>
                <div className="sign_btn">
                    <button className='signup'>Sign up</button>
                </div>
                <div className="haveaccount">
                    <p>Already have an account? <Link to="/">Sign in.</Link></p>
                </div>
            </form>
            <div className="privacy">
                <Link to="/">Term of use. Privacy policy</Link>
            </div>
        </div>
    )
}

export default Signup

