import React from 'react'
import Logo from './Logo.png'
import './ForgotPass.css'
import { useState } from 'react'
import { CognitoUser } from 'amazon-cognito-identity-js'
import UserPool from '../Context/Notes/UserPool'
import { Link, useNavigate } from 'react-router-dom'

export default function ForgetPass() {
    const [stage, setStage] = useState(1); //1 = email stage, 2= enter code stage
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [labelMsg, setLabelMsg] = useState('');

    let navigate = useNavigate();

    const getUser = () => {
        return new CognitoUser({
            Username: email,
            Pool: UserPool
        });
    };

    const sendCode = e => {
        e.preventDefault();
        getUser().forgotPassword({
            onSuccess: data => {
                console.log("OnSuccess :" + data)
            },
            onFailure: err => {
                console.log("OnFailure :" + err)
            },
            inputVerificationCode: data => {
                console.log(data);
                setStage(2);
                setLabelMsg('Enter verification code and new password.')
            }
        });
    };

    const resetPassword = e => {
        e.preventDefault();

        getUser().confirmPassword(code, password, {
            onSuccess: data => {
                console.log("OnSuccess :", data);
                setLabelMsg('Password reset successfully');
                navigate("/");
            },
            onFailure: err => {
                console.log("Onfailure :", err);
            }
        });
    };

    return (
        <div>
            <div className="forgot_center">
                <div className="forgot_logo">
                    <img src={Logo} alt="logo" />
                </div>
                {stage === 1 && (
                    <form onSubmit={sendCode}>
                        <div className="forgot-txt">
                            <p>Enter your email and we send you a reset link.</p>
                        </div>
                        <div className='email_input'>
                            <input type="email" className='email_txt' name='email' value={email} onChange={e => setEmail(e.target.value)} placeholder='Email' required />
                        </div>
                        <div className="btn_container">
                            <button className='req_btn'>Send Code</button>
                        </div>
                    </form>
                )}
                {stage === 2 && (
                    <form onSubmit={resetPassword}>
                        <div className="forgot-txt">
                            <p>{labelMsg}</p>
                        </div>
                        <div className='code_input' style={{marginBottom: "15px"}}>
                            <input type="text" className='email_txt' name='code' value={code} onChange={e => setCode(e.target.value)} placeholder='Enter verfication code.' required />
                        </div>
                        <div className="newpass" style={{marginBottom: "40px"}}>
                            <input type="password" className='email_txt' name='reset' value={password} onChange={e => setPassword(e.target.value)} placeholder='Enter new password.' required />
                        </div>
                        <div className="btn_container" style={{  }}>
                            <button className='req_btn'>Reset Password</button>
                        </div>
                    </form>
                )}
                <div className="privacy" style={{ paddingTop: "100px" }}>
                    <Link className='policy' to="/">Term of use. Privacy policy</Link>
                </div>
            </div>
        </div>
    )
}
