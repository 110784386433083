import { React, useEffect, useState } from 'react'
import banner from './banner.png'
import './Dashboard.css'
import Header from './Header'
import SideMenu from './SideMenu'
import { Navigate } from "react-router-dom";


export default function Dashboard() {

    const [authenticated, setauthenticated] = useState(null);
    useEffect(() => {
        const loggedInUser = localStorage.getItem("authenticated");
        if (loggedInUser) {
            setauthenticated(loggedInUser);
        }
    }, []);

    if (!authenticated) {
        // redirect to login page
        return <Navigate replace to="/"/>
    } else {
        return (
            <>
                <div >
                    <section className="layout-container-content d-flex flex-fill">
                        <SideMenu />
                        <section className="d-flex flex-fill flex-column" >
                            <main role="main" className="layout-main flex-fill" style={{ backgroundImage: `url(${banner}` }} >
                                <Header />
                                <hr />

                            </main>

                        </section>
                    </section>
                </div>
            </>
        )
    }
}
