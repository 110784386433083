import AuthContext from "./authContext";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthState = (props) => {
    //store Id token on successfully login
    // const hardwareData = [
    //     {
    //         "hardwares": [
    //             {
    //                 "eleck_finger_print": "97e23604741fccd757bcca952d1ece67b08635763d4a0c2f56673ae76427d834",
    //                 "user_id": "6549fa03-73be-4416-90fd-164b3bba30b2",
    //                 "name": "tset",
    //                 "created_at": "2022-06-15T15:03:31",
    //                 "updated_at": "2022-09-18T10:46:56"
    //             }
    //         ]
    //     }
    // ];
    // var IdToken = "";
    // var eleckFingerPrint = "";
    // const invokeUrl = 'https://pen83jzv30.execute-api.us-east-1.amazonaws.com/prod'

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated") || false));

    let navigate = useNavigate();
    //TODO: login 
    const UserLogin = () => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        });

        user.authenticateUser(authDetails, {
            onSuccess: data => {
                const IdToken = data.getIdToken().getJwtToken();
                console.log(IdToken);
                setauthenticated(true)
                localStorage.setItem("authenticated", true);

                navigate("/dashboard");
            },

            onFailure: err => {
                console.error("On Failure:", err);
            },

            newPasswordRequired: data => {
                console.log("new password required:", data);
            }
        });
    }

    //Set eleck fingerprint value
    // const GetEleckFingerPrint = async () => {

    //     const bearer = 'Bearer ' + IdToken;

    //     const response = await fetch(`${invokeUrl}/hardware/registered`, {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': bearer,
    //         },
    //     });

    //     const json = await response.json(hardwareData)
    //     eleckFingerPrint = json.hardwares.eleck_finger_print;
    //     HardwareData();
    // }
    //POST : HARDWARE DATA
    // const HardwareData = async (data = {
    //     "term": "day",
    //     "startTime": "2022/1/23T16:00",
    //     "endTime": "2023/3/23T16:00"
    // }) => {
    //     const bearer = 'Bearer ' + IdToken;

    //     const response = await fetch(`${invokeUrl}/hardware/${eleckFingerPrint}`, {
    //         method: 'POST',
    //         headers: {
    //             'Authorization': bearer,
    //         },
    //         body: JSON.stringify(data)
    //     });

    //     console.log(response.json);
    // }

    return (
        <AuthContext.Provider value={{ email, setEmail, password, setPassword, UserLogin }}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState